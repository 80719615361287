import Logo from '../images/myopinion.png';

const Card = ({opinion, ... props}) => {

    return (
        <>
            <div className="card mb-3 shadow  p-1" style={{maxWidth: 700}}>
                <div className="row no-gutters">
                    <div className="col-md-4 d-flex">
                        <img className={"img-fluid rounded"} src={opinion.sond_picture} alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h5 className="card-title">
                                N°{opinion?.rang} - {opinion.user}
                            </h5>
                            <p className="card-text">
                                <small className="text-muted">
                                    Pays: <img height={15} width={15} src={opinion?.ip_country_flag} />{' '}
                                    {opinion?.ip_country_name}
                                </small> <br/>
                                <small className="text-muted">Note: {opinion?.challenge_rank || 0}</small> <br/>
                                <small className="text-muted">Nombre de vues: {opinion?.sond_views || 0}</small> <br/>
                                <small className="text-muted">Nombre de soutiens: {opinion?.soutiens_count || 0}</small> <br/>
                                <small className="text-muted">Nombre de partages: {opinion?.shares_count || 0}</small> <br/>
                                <small className="text-muted">Nombre de Commentaires: {opinion?.comments_count || 0}</small> <br/>
                                <small className="text-muted">Nombre de Suggestions: {opinion?.suggestions_count || 0}</small> <br/>
                                <small className="text-muted">Coins offerts (Pas pris en compte): {Number.parseInt(opinion?.coins_gifted) || 0}</small> <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default Card;