import './App.css';
import Logo from './images/myopinion.png';
import Loader from './images/loader.gif';
import { useState, useEffect } from 'react';
import Card from "./components/Card";
import configData from "./config.json";

const VIEW_TOP_10 = 'VIEW_TOP_10';
const VIEW_ALL = 'VIEW_ALL';

function App() {

    const [opinions, setOpinions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('');
    const [filteredOpinions, setFilteredOpinions] = useState([]);

    const toggleViewMode = (e) => {

        if(viewMode === VIEW_TOP_10) {
            setViewMode(VIEW_ALL);
        }
        else {
            setViewMode(VIEW_TOP_10);
        }

    }



    useEffect(() => {

        fetch(`${configData.API_ENDPOINT}/challenge/reseau-na-bisso`, {
            method: 'GET'
        })
            .then(r => r.json())
            .then(data => {
                const result = data.map((obj, index) => ({
                    ...obj,
                    rang: ++index
                }));

                setOpinions(result);
                setViewMode(VIEW_TOP_10);
                setLoading(false);
            });

    }, []);

    useEffect(() => {

        if(viewMode === VIEW_TOP_10) {
            setFilteredOpinions([...opinions.slice(0, 10)]);
        }
        else {
            setFilteredOpinions([...opinions]);
        }

    }, [viewMode]);

    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                <section>
                    <img alt="MyOpinion" src={Logo} height={80} width={100} />
                    <span className="text-info display-4">MyOpinion <u>#reseaunabisso</u> Challenge</span>
                </section>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
                <div className="alert alert-dark col-10" role="alert">
                    <h4 className="alert-heading">Information</h4>
                    <p>
                        La note de chaque opinion pour ce challenge est determinée selon la formule de calcul ci-dessous qui ne tient pas compte des coins offerts a l'auteur de cette publication.
                    </p>
                    <hr/>
                    <p className="mb-0 font-italic">
                        Note = (Nombre de commentaires * 2) + (Nombre de suggestions * 2) + Nombre de vues + Nombres de partages + Nombres de soutiens (likes/j'aimes)
                    </p>
                </div>
            </div>

            {loading ? (
                <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                    <img alt="Loader" src={Loader} />
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center">
                    {viewMode === VIEW_ALL && (
                        <button className="btn btn-info font-weight-bold m-2" onClick={toggleViewMode}>
                            Voir le Top 10 uniquement
                        </button>
                    )}
                    {viewMode === VIEW_TOP_10 && (
                        <button className="btn btn-primary font-weight-bold m-2" onClick={toggleViewMode}>
                            Voir tout le classement
                        </button>
                    )}
                    {filteredOpinions.map(opinion => <Card key={opinion?.sond_id} opinion={opinion} />)}
                </div>
            )}
        </>
    );
}

export default App;
